import * as React from 'react';
import {useEffect, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import MySelectField from "./MySelectField";
import {
    Box,
    Divider,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";

export default function App() {

    const [attackStatModifier, setAttackStatModifier] = useState(5);
    const [proficiencyBonus, setProficiencyBonus] = useState(4);
    const [numberOfAttacks, setNumberOfAttacks] = useState(2);
    const [flatToHitBonus, setFlatToHitBonus] = useState(0);
    const [flatDamageBonus, setFlatDamageBonus] = useState(0);
    const [critResults, setCritResults] = useState(1);
    const [advantage, setAdvantage] = useState(0);
    const [gwmSs, setGwmSs] = useState(1);
    const [arcaneJolt, setArcaneJolt] = useState(0);
    const [minEnemyAc, setMinEnemyAc] = useState(8);
    const [maxEnemyAc, setMaxEnemyAc] = useState(25);

    const [results, setResults] = useState({
        toHitBonus: "0",
        critChance: "0",
        table: []
    });

    const attackStatModifierOptions = Array.from({length: 7}, (_, i) => ({label: `${i}`, value: i}));
    const proficiencyBonusOptions = Array.from({length: 5}, (_, i) => ({label: `${i + 2}`, value: i + 2}));
    const numberOfAttacksOptions = Array.from({length: 5}, (_, i) => ({label: `${i}`, value: i}));
    const flatBonusOptions = Array.from({length: 11}, (_, i) => ({label: `${i}`, value: i}));
    const critChanceOptions = [
        {label: "20", value: 1},
        {label: "19, 20", value: 2},
        {label: "18, 19, 20", value: 3}
    ];
    const advantageOptions = [
        {label: "Super Advantage", value: 2},
        {label: "Advantage", value: 1},
        {label: "Normal", value: 0},
        {label: "Disadvantage", value: -1}
    ];
    const gwmSsOptions = [
        {label: "no", value: 0},
        {label: "yes", value: 1}
    ];
    const arcaneJoltOptions = [
        {label: "-", value: 0},
        {label: "2d6", value: 2},
        {label: "4d6", value: 4}
    ];
    const acOptions = Array.from({length: 31}, (_, i) => ({label: `${i}`, value: i}));

    useEffect(() => {
        const gwmSsAttackMod = gwmSs > 0 ? -5 : 0;
        const gwmSsDamageMod = gwmSs > 0 ? 10 : 0;
        const characterAttackBonus = attackStatModifier + proficiencyBonus + flatToHitBonus + 2 + gwmSsAttackMod;

        const rollsCrit = Array.from({length: critResults}, (_, i) => i + 21 - critResults);
        const critChanceArray = rollsCrit.map(roll => mapHitChance(roll, advantage));
        const critChance = critChanceArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);

        // console.log(`Character Attack Bonus: ${characterAttackBonus}`);
        const resultArray = [];
        for (let enemyAc = minEnemyAc; enemyAc <= maxEnemyAc; enemyAc++) {

            const minRollToHit = Math.max(enemyAc - characterAttackBonus, 1);
            const dieResultsHit = Math.max(20 - minRollToHit + 1, 0);

            // console.log(`${enemyAc} AC : ${minRollToHit} - ${dieResultsHit}`);

            const rollsHit = Array.from({length: dieResultsHit}, (_, i) => i + minRollToHit);
            const hitChanceArray = rollsHit.map(roll => mapHitChance(roll, advantage));
            const hitChance = Math.max(hitChanceArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0), critChance);

            const hitDamage = hitChance * ((numberOfAttacks * (16 + gwmSsDamageMod)) + arcaneJolt * 3.5);
            const critDamage = critChance * ((numberOfAttacks * 16) + arcaneJolt * 6);
            const avgDamage = hitDamage + critDamage;

            // console.log(`( ${ac} AC ) hits: ${hitChanceArray} ${hitChanceArray.length} crits: ${critChanceArray} ${critChanceArray.length}`);
            // console.log(`( ${ac} AC ) hits: ${(hitChance*100).toFixed(2)} crits: ${(critChance*100).toFixed(2)} damage: ${(hitDamage + critDamage).toFixed(2)}`);
            resultArray.push({
                ac: enemyAc,
                hitChance: (hitChance * 100).toFixed(2),
                avgDamage: avgDamage.toFixed(2)
            });
        }
        setResults({
            toHitBonus: characterAttackBonus,
            critChance: (critChance * 100).toFixed(2),
            table: resultArray
        });
    }, [
        attackStatModifier,
        proficiencyBonus,
        numberOfAttacks,
        flatToHitBonus,
        flatDamageBonus,
        critResults,
        advantage,
        gwmSs,
        arcaneJolt,
        minEnemyAc,
        maxEnemyAc
    ]);

    useEffect(() => {
        console.log(results);
    }, [results]);

    function mapHitChance(roll, rollType) {
        if (rollType === 2) {
            return (3 * roll * (roll - 1) + 1) / 8000
        } else if (rollType === 1) {
            return ((2 * roll) - 1) / 400;
        } else if (rollType === -1) {
            return ((2 * (21 - roll)) - 1) / 400;
        } else {
            return 0.05;
        }
    }

    // useEffect(() => {
    //     const characterAttackBonus = attackStatModifier + proficiencyBonus + flatToHitBonus + 2;
    //     const critChance = critResults * 0.05;
    //     console.log(`Character Attack Bonus: ${characterAttackBonus}`);
    //     console.log(`Crit Chance: ${Math.round(critChance * 100)} %`);
    //
    //     for (let enemyAc = 0; enemyAc <= 30; enemyAc++) {
    //         const minRollToHit = Math.max(enemyAc - characterAttackBonus, 0);
    //         const dieResultsHit = 20 - minRollToHit + 1;
    //
    //         const hitChance = Math.max(Math.min(dieResultsHit / 20, 1), critChance);
    //
    //         const ac = enemyAc < 10 ? ' ' + enemyAc : '' + enemyAc;
    //         console.log(`( ${ac} AC ) Hit: ${Math.round(hitChance * 100)} % -- ${minRollToHit} ${minRollToHit > (20-critResults) ? '(Crit only)' : ''}`);
    //     }
    // });

    return (
        <>
            <CssBaseline/>
            <main style={{margin: "15px"}}>
                <Stack spacing={2} direction="row">
                    <Box sx={{p: 2, display: 'inline-block'}}>
                        <Stack spacing={2}>
                            <MySelectField label="Attack Stat Modifier" val={attackStatModifier}
                                           setVal={setAttackStatModifier}
                                           options={attackStatModifierOptions}/>
                            <MySelectField label="Proficiency Bonus" val={proficiencyBonus} setVal={setProficiencyBonus}
                                           options={proficiencyBonusOptions}/>
                            <MySelectField label="Number of Attacks" val={numberOfAttacks} setVal={setNumberOfAttacks}
                                           options={numberOfAttacksOptions}/>
                            <MySelectField label="Flat To-Hit Bonus" val={flatToHitBonus} setVal={setFlatToHitBonus}
                                           options={flatBonusOptions}/>
                            <MySelectField label="Flat Damage Bonus" val={flatDamageBonus} setVal={setFlatDamageBonus}
                                           options={flatBonusOptions}/>
                            <MySelectField label="Crits on" val={critResults} setVal={setCritResults}
                                           options={critChanceOptions}/>
                            <MySelectField label="Advantage" val={advantage} setVal={setAdvantage}
                                           options={advantageOptions}/>
                            <MySelectField label="GWM / SS" val={gwmSs} setVal={setGwmSs}
                                           options={gwmSsOptions}/>
                            <MySelectField label="Arcane Jolt" val={arcaneJolt} setVal={setArcaneJolt}
                                           options={arcaneJoltOptions}/>
                            <Divider/>
                            <MySelectField label="min AC" val={minEnemyAc} setVal={setMinEnemyAc}
                                           options={acOptions}/>
                            <MySelectField label="max AC" val={maxEnemyAc} setVal={setMaxEnemyAc}
                                           options={acOptions}/>
                        </Stack>
                    </Box>
                    <Box sx={{p: 2, display: 'inline-block'}}>
                        <TableContainer component={Paper} sx={{marginBottom: 2}}>
                            <Table size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Stat</TableCell>
                                        <TableCell align="center">Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row">To-Hit Bonus</TableCell>
                                        <TableCell align="center">+{results.toHitBonus}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row">Crit Chance</TableCell>
                                        <TableCell align="center">{results.critChance}%</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Stack spacing={2}>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">AC</TableCell>
                                            <TableCell align="right">Hit %</TableCell>
                                            <TableCell align="right">Avg Dmg / Round</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {results.table.map((row) => (
                                            <TableRow
                                                key={row.ac}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row" align="right">
                                                    {row.ac}
                                                </TableCell>
                                                <TableCell align="right">{row.hitChance}</TableCell>
                                                <TableCell align="right">{row.avgDamage}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Box>
                </Stack>
            </main>
        </>
    );
}
