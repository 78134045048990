import {MenuItem, TextField} from "@mui/material";

export default function MySelectField(props) {

    const handleChange = (event) => {
        props.setVal(event.target.value);
    };

    return (
        <TextField
            select
            label={props.label}
            value={props.val}
            onChange={handleChange}
            size="small"
            style={{width: "20ch", textAlign: "right"}}
        >
            {props.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
}
